import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Field,
  FieldArray,
  reduxForm,
  type WrappedFieldArrayProps,
  type WrappedFieldProps,
} from "redux-form";

import { Input } from "~/components/ui/input";

function LinksCT() {
  return <FieldArray name="links" component={renderFields} />;
}

type TLink = { link?: string };
export type ReduxFormLinks = { links: TLink[] };

const initialValues: ReduxFormLinks = { links: [{}], };
export default reduxForm({ form: "links", initialValues })(LinksCT);

function renderFields({ fields }: WrappedFieldArrayProps) {
  return (
    <div style={{ display: "inline-block", width: "100%", marginBottom: "20px" }}>
      {fields.map(RenderField)}
    </div>
  );
}

function RenderField(
  item: string,
  index: number,
  fields: WrappedFieldArrayProps<TLink>["fields"],
) {
  const removeField = () => {
    if (fields.length > 1) {
      fields.remove(index);
    }
  };

  const addField = () => {
    fields.push({});
  };

  return (
    <div className="row" key={index}>
      <div className="col-md-10 col-xs-8 col-sm-6">
        <Field
          component={renderFieldValidation}
          name={`${item}link`}
          className="form-control"
          type="text"
          validate={[validateLink]}
          normalize={(v: string) => v.trim()}
          id={`${item}link`}
        />
        <label
          className="control-label"
          id="validation-link"
          style={{ fontSize: "12px", fontStyle: "italic" }}
          htmlFor={`${item}link`}
        >
          (<FormattedMessage id="ADD_LINK_TO_CT" />)
        </label>
      </div>
      <button
        style={{ marginRight: "5px" }}
        className="btn btn-danger"
        type="button"
        onClick={removeField}
      >
        -
      </button>
      <button className="btn btn-success" type="button" onClick={addField}>
        +
      </button>
    </div>
  );
}

function renderFieldValidation({ input, meta, ...props }: WrappedFieldProps) {
  const error = meta.touched && meta.error;
  return (
    <div className={error ? "has-error" : ""}>
      <Input {...input} {...props} />
    </div>
  );
}

function validateLink(value: string) {
  if (value !== "") {
    return false;
  }
  return true;
}

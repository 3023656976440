import clsx from "clsx";
import * as React from "react";

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  rounded?: boolean;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { rounded, className, rows = 5, ...props },
  forwardedRef,
) {
  return (
    <textarea
      rows={rows}
      className={clsx(
        // NOTE: Using font-size less than 16px, zooms the input in on ios and doesn't zoom back after blur.
        "form-control",
        "tw-text-base sm:tw-text-sm",
        "tw-outline-none tw-ring-blue-600 focus-visible:tw-ring-1",
        { "tw-rounded-md": rounded },
        className,
      )}
      ref={forwardedRef}
      {...props}
    />
  );
});

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { COURSE_VERSION_V2, COURSE_VERSION_V3 } from "~/common/courses";
import ProbablyFormattedMessage from "~/components/probably_formatted_message";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatientList } from "~/reducers/dashboard";
import type { TPatient } from "~/types/patient";

export function PatientCardCourse({ patient }: { patient: TPatient }) {
  return (
    <LabelledValue label={<FormattedMessage id="HEADER_INSTRUCTIONS" />}>
      <CourseName patient={patient} />
    </LabelledValue>
  );
}

export function CourseName({
  patient,
}: {
  patient: Pick<TPatientList, "course" | "course_version">;
}) {
  const intl = useIntl();

  if (intl.locale == "ru") {
    if (patient.course_version == COURSE_VERSION_V2) {
      return <ProbablyFormattedMessage id={`${patient.course.course}_V2`} />;
    } else if (patient.course_version == COURSE_VERSION_V3) {
      return <ProbablyFormattedMessage id={`${patient.course.course}_V3`} />;
    }
  }

  return <ProbablyFormattedMessage id={patient.course.course} />;
}

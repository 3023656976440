import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { getPatientId } from "~/actions/get_patient_id";
import { isPatient } from "~/common/patient";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { useDocumentTitle } from "~/hooks/use-document-title";
import { useAppDispatch, useAppSelector } from "~/store";

import { PatientDeliveries } from "./patient_show/patient_deliveries";
import { PatientId } from "./patient_show/patient_id";

export default function PatientDeliveryPage() {
  const { patient_id } = useParams<{ patient_id: string }>();
  const intl = useIntl();
  useDocumentTitle(
    intl.formatMessage({ id: "pat.block.deliveries.deliveries.impressions" }) + " " + patient_id,
  );

  const patient = useAppSelector((state) => state.patient);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    void dispatch(getPatientId(Number(patient_id)));
  }, [patient_id, dispatch]);

  const patientIsLoading = !isPatient(patient);

  return (
    <Layout>
      {patientIsLoading ? (
        <Loader />
      ) : (
        <main className="row">
          <div className="col-md-8">
            <RemoteLogErrorBoundary component="patient_deliveries">
              <PatientDeliveries />
            </RemoteLogErrorBoundary>
          </div>

          <div className="col-md-4">
            <PatientId patient={patient} />
          </div>
        </main>
      )}
    </Layout>
  );
}

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isChildrenShortCourse } from "~/common/courses";
import { getLastCorrection } from "~/common/patient";
import { deployedUSA } from "~/common/utils";
import { FormatDate } from "~/components/common/FormatDate";
import { PersonName } from "~/components/common/PersonName";
import { LabelledValue } from "~/components/ui/labelled-value";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";

export function PatientId({ patient }: { patient: TPatient }) {
  const intl = useIntl();

  const warranty_end_date = patient.warranty_end_date;
  const bonus = patient.bonus == true;
  const lastCorrection = getLastCorrection(patient);
  const start_date = lastCorrection.start_date;
  const plan_date = lastCorrection.plan_date;
  const steps_count_total = lastCorrection.steps_count_total;
  const approval_timestamp = lastCorrection.approval_timestamp;
  const deliveryEstimate = patient.delivery_estimate;
  const deliveryAdditionalEstimate = patient.delivery_additional_estimate;
  const deliveryRetainersEstimate = patient.delivery_retainers_estimate;

  return (
    <Portlet id="patient-info-side-block">
      <PortletTitle as="h2" iconClassName="icon-user">
        <FormattedMessage id="pat.block.right.header" />

        {bonus ? (
          <img
            style={{ width: "2rem", marginLeft: "1rem" }}
            src="/img/bonus.svg"
            title={intl.formatMessage({ id: "bonus.patient" })}
            alt=""
          />
        ) : null}
      </PortletTitle>

      <div className="portlet-body" id="patient-info-side-block-body">
        <LabelledValue label={<FormattedMessage id="pat.block.right.id" />}>
          {patient.patient_id}
        </LabelledValue>

        <LabelledValue label={<FormattedMessage id="pat.block.right.name" />}>
          <PersonName person={patient} />
        </LabelledValue>

        <LabelledValue label={<FormattedMessage id="pat.block.right.instructions" />}>
          <FormatDate value={patient.timestamp} date />
        </LabelledValue>

        {warranty_end_date ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.warranty_end_date" />}>
            <FormatDate value={warranty_end_date} date />
          </LabelledValue>
        ) : null}

        {patient.course.correction.slice(1).map((correction, index) => (
          <LabelledValue
            key={index}
            label={
              <>
                <FormattedMessage
                  id={
                    isChildrenShortCourse(patient.course.course_id)
                      ? "pat.block.right.additional_course"
                      : "pat.block.right.correction"
                  }
                />
                {` ${index + 1}`}
              </>
            }
          >
            <FormatDate value={correction.timestamp} date />
          </LabelledValue>
        ))}

        {start_date != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.start_date" />}>
            <FormatDate value={start_date} date />
          </LabelledValue>
        ) : null}

        {plan_date != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.plan_date" />}>
            <FormatDate value={plan_date} date />
          </LabelledValue>
        ) : null}

        {approval_timestamp != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.plan_approval" />}>
            <FormatDate value={approval_timestamp} date />
          </LabelledValue>
        ) : null}

        {deployedUSA() && deliveryEstimate != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.aligners.eta" />}>
            <FormatDate value={deliveryEstimate} date />
          </LabelledValue>
        ) : null}

        {deployedUSA() && deliveryAdditionalEstimate != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.additional.eta" />}>
            <FormatDate value={deliveryAdditionalEstimate} date />
          </LabelledValue>
        ) : null}

        {deployedUSA() && deliveryRetainersEstimate != null ? (
          <LabelledValue label={<FormattedMessage id="pat.block.right.retainers.eta" />}>
            <FormatDate value={deliveryRetainersEstimate} date />
          </LabelledValue>
        ) : null}

        {steps_count_total != "None" ? (
          <LabelledValue label={<FormattedMessage id="p.patient.correction.stages" />}>
            {steps_count_total}
          </LabelledValue>
        ) : null}
      </div>
    </Portlet>
  );
}

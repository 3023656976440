import React from "react";
import { FormattedMessage } from "react-intl";

import { translateRxOption } from "~/common/prescription";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPrescription } from "~/types/prescription";

export function PatientCardCanines({
  prescription: {
    canine_ratio_right,
    canine_ratio_left,
    canine_method_ai,
    canine_method,
    canine_ipr,
  },
}: {
  prescription: TPrescription;
}) {
  return (
    <div>
      <h4 style={{ fontWeight: "500" }} className="block">
        <FormattedMessage id="HEADER_CANINE" />
      </h4>

      <div className="row">
        <LabelledValue
          className="form-group col-md-3"
          label={<FormattedMessage id="CANINE_RIGHT" />}
        >
          <FormattedMessage id={translateRxOption("canine_ratio_right", canine_ratio_right)} />
        </LabelledValue>

        <LabelledValue
          className="form-group col-md-3"
          label={<FormattedMessage id="CANINE_LEFT" />}
        >
          <FormattedMessage id={translateRxOption("canine_ratio_left", canine_ratio_left)} />
        </LabelledValue>

        {canine_method_ai ? (
          <LabelledValue
            className="form-group col-lg-4 col-sm-6"
            label={<FormattedMessage id="CANINE_HOWTO" />}
          >
            <FormattedMessage id={translateRxOption("canine_method_ai", `${canine_method_ai}`)} />
          </LabelledValue>
        ) : (
          <>
            <LabelledValue
              className="form-group col-md-3"
              label={<FormattedMessage id="CANINE_HOWTO" />}
            >
              <FormattedMessage id={translateRxOption("canine_method", canine_method)} />
            </LabelledValue>

            {canine_ipr ? (
              <div className="form-group col-lg-3 col-sm-5">
                <p style={{ marginTop: "10px" }}>
                  <FormattedMessage id={translateRxOption("canine_ipr", `${canine_ipr}`)} />
                </p>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

import moment, { type Moment } from "moment";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { Gender, Payer } from "~/common/constants";
import { isChildrenCourse, isTeenCourse, Status } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { isPatient } from "~/common/patient";
import {
  BirthDateInput,
  GenderRadioGroup,
} from "~/components/patient/patient_new/patient_new_patient_info";
import { Label } from "~/components/ui/label";
import { LabelledInput } from "~/components/ui/labelled-input";
import { addInstructions, removeInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";
import type { SetDirtyFn } from "~/types/patient-new";

const mapStateToProps = (state: RootState) => {
  return {
    instructions: state.instructions,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstruction: removeInstructions,
};

type PatientUpdatePatientInfoProps = PropsFromRedux & {
  intl: IntlShape;
  setDirty: SetDirtyFn;
  patient: TPatient;
};

type PatientUpdatePatientInfoState = {
  startDate: Moment | null;
};

class PatientUpdatePatientInfo extends Component<
  PatientUpdatePatientInfoProps,
  PatientUpdatePatientInfoState
> {
  constructor(props: PatientUpdatePatientInfoProps) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.addMiddleName = this.addMiddleName.bind(this);
    this.selectSex = this.selectSex.bind(this);
    this.addBdate = this.addBdate.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.renderPayerFields = this.renderPayerFields.bind(this);
    this.addPayerFirstName = this.addPayerFirstName.bind(this);
    this.addPayerLastName = this.addPayerLastName.bind(this);
    this.addPayerMiddleName = this.addPayerMiddleName.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_update_patient_info");
  }

  componentDidMount() {
    const { patient } = this.props;
    if (isPatient(patient)) {
      this.addFirstName(patient.first_name);
      this.addLastName(patient.last_name);
      this.addMiddleName(patient.middle_name.toLowerCase() === "none" ? null : patient.middle_name);
      this.selectSex(patient.sex);
      if (patient.bdate && patient.bdate.toLowerCase() !== "none") {
        this.addBdate(moment(patient.bdate));
      }
      if (this.props.intl.locale == "ru") {
        this.addEmail(patient.email ? patient.email : null);
        this.addPayerFirstName(patient.payer_first_name ? patient.payer_first_name : null);
        this.addPayerLastName(patient.payer_last_name ? patient.payer_last_name : null);
        this.addPayerMiddleName(patient.payer_patronymic ? patient.payer_patronymic : null);
      }
    }
  }

  addFirstName(data: string) {
    this.props.addInstruction({ first_name: data });
    this.props.setDirty("first_name", data);
  }

  addLastName(data: string) {
    this.props.addInstruction({ last_name: data });
    this.props.setDirty("last_name", data);
  }

  addMiddleName(data: string | null) {
    this.props.addInstruction({ middle_name: data });
    this.props.setDirty("middle_name", data);
  }

  addPayerFirstName(data: string | null) {
    this.props.addInstruction({ payer_first_name: data });
    this.props.setDirty("payer_first_name", data);
  }

  addPayerLastName(data: string | null) {
    this.props.addInstruction({ payer_last_name: data });
    this.props.setDirty("payer_last_name", data);
  }

  addPayerMiddleName(data: string | null) {
    this.props.addInstruction({ payer_patronymic: data });
    this.props.setDirty("payer_patronymic", data);
  }

  selectSex(data: TPatient["sex"] | string | null) {
    if (data == Gender.FEMALE || data == Gender.MALE) {
      this.props.addInstruction({ sex: typeof data == "string" ? parseInt(data) : data });
      this.props.setDirty("sex", typeof data == "string" ? parseInt(data) : data);
    }
  }

  addBdate(data: Moment) {
    if (data) {
      this.setState({ startDate: data });
      this.props.addInstruction({ bdate: moment(data).format("YYYY-MM-DD") });
      this.props.setDirty("bdate", moment(data).format("YYYY-MM-DD"));
    }
  }

  addEmail(data: string | null) {
    this.props.addInstruction({ email: data });
    this.props.setDirty("email", data);
  }

  renderPayerFields() {
    const { intl, patient, instructions } = this.props;

    const isPayerNameRequired = instructions.course_id
      ? isChildrenCourse(instructions.course_id) || isTeenCourse(instructions.course_id)
      : false;

    if (intl.locale == "ru" && instructions.payer_id == Payer.PATIENT) {
      if (patient.status == Status.UNFILLED_CASE) {
        return (
          <div>
            <LabelledInput
              label={<FormattedMessage id="PAT_EMAIL" />}
              labelProps={{ id: "validation-email" }}
              id="email"
              required
              name="email"
              placeholder={intl.formatMessage({ id: "PAT_INFO_EMAIL_PLACEHOLDER" })}
              value={instructions.email ?? ""}
              onChange={(e) => this.addEmail(e.target.value)}
              maxLength={256}
            />

            <div className="row">
              <div className="col-md-8">
                <Label semibold readOnly>
                  <FormattedMessage id="another.payer.person" />
                </Label>
              </div>
            </div>

            <div className="row" id="patient-block-body">
              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_LAST_NAME" />}
                  id="payer-lastname-value"
                  required={isPayerNameRequired}
                  name="payer_last_name"
                  placeholder={intl.formatMessage({ id: "PAYER_LAST_NAME_PLACEHOLDER" })}
                  value={instructions.payer_last_name ?? ""}
                  onChange={(e) => this.addPayerLastName(e.target.value)}
                  maxLength={64}
                />
              </div>

              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_FIRST_NAME" />}
                  id="payer-firstname-value"
                  required={isPayerNameRequired}
                  name="payer_first_name"
                  placeholder={intl.formatMessage({ id: "PAYER_FIRST_NAME_PLACEHOLDER" })}
                  value={instructions.payer_first_name ?? ""}
                  onChange={(e) => this.addPayerFirstName(e.target.value)}
                  maxLength={64}
                />
              </div>

              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_MIDDLE_NAME" />}
                  id="payer-middlename-value"
                  required={isPayerNameRequired}
                  name="payer_patronymic"
                  placeholder={intl.formatMessage({ id: "PAYER_MIDDLE_NAME_PLACEHOLDER" })}
                  value={instructions.payer_patronymic ?? ""}
                  onChange={(e) => this.addPayerMiddleName(e.target.value)}
                  maxLength={64}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ marginBottom: "20px" }}>
            <label className="control-label" id="validation-email" style={{ fontWeight: "900" }}>
              <FormattedMessage id="PAT_EMAIL" />
              <br />
              <span data-matomo-mask data-hj-suppress style={{ fontWeight: "400" }}>
                {patient.email}
              </span>
            </label>
          </div>
        );
      }
    }

    return null;
  }

  render() {
    const { intl, patient } = this.props;
    const isNameReadOnly = patient.status != Status.UNFILLED_CASE;

    return (
      <div>
        <h3 className="block" id="patient-block-label" style={{ fontWeight: "900" }}>
          <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
        </h3>

        <div className="row" id="patient-block-body">
          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_LAST_NAME" />}
              labelProps={{
                id: "patient-lastname-label",
                tooltip: <FormattedMessage id="PAT_INFO_NAME_TOOLTIP" />,
              }}
              id="patient-lastname-value"
              required
              type="text"
              name="last_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_LAST_NAME_PLACEHOLDER" })}
              onChange={isNameReadOnly ? undefined : (e) => this.addLastName(e.target.value)}
              defaultValue={patient.last_name}
              readOnly={isNameReadOnly}
            />
          </div>

          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_FIRST_NAME" />}
              labelProps={{ id: "patient-firstname-label" }}
              id="patient-firstname-value"
              required
              type="text"
              name="first_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_FIRST_NAME_PLACEHOLDER" })}
              onChange={isNameReadOnly ? undefined : (e) => this.addFirstName(e.target.value)}
              defaultValue={patient.first_name}
              readOnly={isNameReadOnly}
            />
          </div>

          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_MIDDLE_NAME" />}
              labelProps={{ id: "patient-middlename-label" }}
              id="patient-middlename-value"
              type="text"
              name="patronymic_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_MIDDLE_NAME_PLACEHOLDER" })}
              onChange={isNameReadOnly ? undefined : (e) => this.addMiddleName(e.target.value)}
              defaultValue={
                patient.middle_name == "none" || patient.middle_name == "None"
                  ? undefined
                  : patient.middle_name
              }
              readOnly={isNameReadOnly}
            />
          </div>
        </div>

        {this.renderPayerFields()}

        <GenderRadioGroup sex={patient.sex} onChange={(e) => this.selectSex(e.target.value)} />

        <BirthDateInput
          selected={this.state.startDate}
          onSelect={(e) => this.addBdate(e)}
          onBlur={() => this.props.removeInstruction({ bdate: null })}
        />
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdatePatientInfo));

import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { change, formValueSelector } from "redux-form";

import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { useAppDispatch, useAppSelector } from "~/store";

const selector = formValueSelector("correction");

export default function CorrectionWithCTRadioGroup() {
  const dispatch = useAppDispatch();
  const correction_with_ct = useAppSelector(
    (state) => selector(state, "correction_with_ct") as boolean | undefined,
  );

  type TOption = { value: boolean; intlId: MessageDescriptor["id"] };
  const options: TOption[] = [
    { value: true, intlId: "C_YES_CT_CORRECTION" },
    { value: false, intlId: "C_NO_CT_CORRECTION" },
  ];

  return (
    <LabelledRadioGroup label={<FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />} required>
      {options.map((option) => (
        <LabelledRadio
          key={option.intlId}
          label={<FormattedMessage id={option.intlId} />}
          name="correction_with_ct"
          checked={option.value == correction_with_ct}
          onChange={() => dispatch(change("correction", "correction_with_ct", option.value))}
        />
      ))}
    </LabelledRadioGroup>
  );
}

import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { getClinics } from "~/actions/clinics";
import { Status } from "~/common/courses";
import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { LabelledValue } from "~/components/ui/labelled-value";
import { addInstructions } from "~/slices/instructions";
import { useAppDispatch, useAppSelector } from "~/store";
import type { TClinic } from "~/types/clinic";
import type { TPatient } from "~/types/patient";
import type { SetDirtyFn } from "~/types/patient-new";

export default function PatientUpdateDoctorInfo({
  patient,
  setDirty,
}: {
  patient: TPatient;
  setDirty: SetDirtyFn;
}) {
  const dispatch = useAppDispatch();
  const clinics = useAppSelector((state) => state.clinics);

  useEffect(() => {
    // Store `clinic_id` inside `instructions`
    if (patient.clinic.clinic_id) {
      dispatch(addInstructions({ clinic_id: patient.clinic.clinic_id }));
    }
  }, [dispatch, patient.clinic.clinic_id]);

  useEffect(() => {
    // Load clinics
    void dispatch(getClinics());
  }, [dispatch]);

  function selectClinic(clinic_id: TClinic["clinic_id"]) {
    dispatch(addInstructions({ clinic_id }));
    setDirty("clinic_id", clinic_id);
  }

  // NOTE: without this patient_update doesn't work. This absolutely horrible!
  const firstCorrection = patient.course.correction[0];

  if (firstCorrection) {
    for (const key of Object.keys(firstCorrection)) {
      if (firstCorrection[key] == "None" || firstCorrection[key] == "NA") {
        firstCorrection[key] = "";
      }
    }
  }

  return (
    <div>
      <h3 className="block" style={{ fontWeight: "900", marginTop: "0" }}>
        <FormattedMessage id="BLOCKHEAD_DOCTOR" />
      </h3>

      {patient.status == Status.UNFILLED_CASE ? (
        <LabelledRadioGroup
          label={<FormattedMessage id="HEADER_CLINIC" />}
          labelProps={{ id: "validation-clinic_id" }}
          required
        >
          {clinics.map((clinic) => (
            <LabelledRadio
              key={clinic.clinic_id}
              label={clinic.title}
              name="clinic"
              value={clinic.clinic_id}
              defaultChecked={patient.clinic.clinic_id == clinic.clinic_id}
              onChange={(e) => selectClinic(Number(e.target.value))}
            />
          ))}
        </LabelledRadioGroup>
      ) : (
        <LabelledValue label={<FormattedMessage id="HEADER_CLINIC" />}>
          {patient.clinic.title}
        </LabelledValue>
      )}
    </div>
  );
}

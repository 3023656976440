import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { getLastCorrection } from "~/common/patient";
import { FormatNumber } from "~/components/common/FormatNumber";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { LabelledValue } from "~/components/ui/labelled-value";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";

export { PatientPaymentErrorBoundary as PatientPayment };

function PatientPaymentErrorBoundary(props: React.ComponentProps<typeof PatientPayment>) {
  return (
    <RemoteLogErrorBoundary component="patient_payment">
      <PatientPayment {...props} />
    </RemoteLogErrorBoundary>
  );
}

function PatientPayment({ patient }: { patient: TPatient }) {
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const toggleDivRef = useRef<HTMLDivElement>(null);

  function togglePayment() {
    setShowPaymentInfo((prevShowPaymentInfo) => !prevShowPaymentInfo);
    $(toggleDivRef.current).slideToggle();
  }

  const currentPayments = patient.total_payments;
  const lastCorrection = getLastCorrection(patient);

  return (
    <Portlet id="payment-info-side-block">
      <PortletTitle as="h2" iconClassName="icon-wallet" id="payment-info-side-block-name">
        <FormattedMessage id="pat.block.payment.header" />
      </PortletTitle>

      <a id="payment-info-side-show-button" onClick={togglePayment}>
        <FormattedMessage id={showPaymentInfo ? "general.payment.close" : "general.payment.show"} />
      </a>

      <div
        className="portlet-body"
        ref={toggleDivRef}
        style={{ display: "none" }}
        id="payment-info-side-block-body"
      >
        <LabelledValue label={<FormattedMessage id="pat.block.payment.status" />}>
          {lastCorrection.payment_status ? (
            <FormattedMessage id={lastCorrection.payment_status} />
          ) : null}
        </LabelledValue>

        <LabelledValue label={<FormattedMessage id="pat.block.payment.received" />}>
          <FormatNumber value={currentPayments.paid} />{" "}
          <FormattedMessage id="pat.block.payment.currency" />
        </LabelledValue>

        <LabelledValue label={<FormattedMessage id="pat.block.payment.total" />}>
          <FormatNumber value={currentPayments.total} />{" "}
          <FormattedMessage id="pat.block.payment.currency" />
        </LabelledValue>
      </div>
    </Portlet>
  );
}

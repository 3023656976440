import React from "react";
import { FormattedMessage } from "react-intl";

import { GENDER_MAP, Payer } from "~/common/constants";
import { FormatDate } from "~/components/common/FormatDate";
import { newLineToLineBreakTag } from "~/components/common/formatText/formatText";
import { PersonName } from "~/components/common/PersonName";
import ProbablyFormattedMessage from "~/components/probably_formatted_message";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";

export function PatientCardPatientInfo({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="patient-info-label">
        <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
      </h3>

      <LabelledValue label={<FormattedMessage id="pat_table.name" />}>
        <PersonName person={patient} />
      </LabelledValue>

      <LabelledValue label={<FormattedMessage id="HEADER_SEX" />}>
        <ProbablyFormattedMessage id={patient.sex ? GENDER_MAP[patient.sex] : undefined} />
      </LabelledValue>

      <LabelledValue label={<FormattedMessage id="HEADER_BDATE" />}>
        {patient.bdate ? <FormatDate value={patient.bdate} date /> : <FormattedMessage id="None" />}
      </LabelledValue>

      {patient.payer_id == Payer.PATIENT ? (
        <LabelledValue label={<FormattedMessage id="PAT_EMAIL" />}>
          {patient.email ? patient.email : <FormattedMessage id="None" />}
        </LabelledValue>
      ) : null}

      <LabelledValue label={<FormattedMessage id="HEADER_CONDITION" />}>
        {patient.diagnosis ? (
          newLineToLineBreakTag(patient.diagnosis)
        ) : (
          <FormattedMessage id="None" />
        )}
      </LabelledValue>
    </div>
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse } from "~/common/courses";
import { translateRxOption } from "~/common/prescription";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";

export function PatientCardMolars({
  course_id,
  prescription: {
    molars_ratio_right,
    molars_ratio_left,
    molars_method_ai,
    molars_method,
    molars_ipr,
  },
}: {
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  return (
    <div>
      <h4 id="molars-section" className="block" style={{ fontWeight: "500" }}>
        <FormattedMessage id="HEADER_MOLAR" />
      </h4>

      {isChildrenCourse(course_id) ? (
        <div className="row">
          <div className="form-group col-md-4">
            <p>
              <FormattedMessage id={translateRxOption("molars_ratio_right", molars_ratio_right)} />
            </p>
          </div>
        </div>
      ) : (
        <div className="row">
          <LabelledValue
            className="form-group col-md-3"
            label={<FormattedMessage id="MOLAR_RIGHT" />}
          >
            <FormattedMessage id={translateRxOption("molars_ratio_right", molars_ratio_right)} />
          </LabelledValue>

          <LabelledValue
            className="form-group col-md-3"
            label={<FormattedMessage id="MOLAR_LEFT" />}
          >
            <FormattedMessage id={translateRxOption("molars_ratio_left", molars_ratio_left)} />
          </LabelledValue>

          {molars_method_ai ? (
            <LabelledValue
              className="form-group col-lg-4 col-sm-6"
              label={<FormattedMessage id="MOLAR_HOWTO" />}
            >
              <FormattedMessage id={translateRxOption("molars_method_ai", `${molars_method_ai}`)} />
            </LabelledValue>
          ) : (
            <>
              <LabelledValue
                className="form-group col-md-3"
                label={<FormattedMessage id="MOLAR_HOWTO" />}
              >
                <FormattedMessage id={translateRxOption("molars_method", molars_method)} />
              </LabelledValue>

              {molars_ipr ? (
                <div className="form-group col-lg-3 col-sm-5">
                  <p style={{ marginTop: "10px" }}>
                    <FormattedMessage id={translateRxOption("molars_ipr", `${molars_ipr}`)} />
                  </p>
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
}

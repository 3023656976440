import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, FieldArray, type WrappedFieldProps } from "redux-form";

import { CloseAllGaps } from "~/common/prescription";
import { Label } from "~/components/ui/label";
import { TextArea } from "~/components/ui/textarea";
import type { ValueOf } from "~/types/common";
import type { TPrescriptionReduxFormChangeFn } from "~/types/redux-form";

import RecipeCloseAllGapsSpaces from "./recipe-close-all-gaps-spaces";
import { RecipeCheckbox } from "./recipe-shared";

export function RecipeCloseAllGaps({
  close_all_gaps,
  change,
}: {
  close_all_gaps: ValueOf<typeof CloseAllGaps> | null | undefined;
  change: TPrescriptionReduxFormChangeFn;
}) {
  return (
    <div id="close-all-gaps-section" style={{ marginTop: 15 }}>
      <div className="form-group">
        <Label id="close-all-gaps-label" readOnly>
          <FormattedMessage id="HEADER_CLOSE_ALL" />
        </Label>

        <div className="checkbox-list checkbox_list">
          <RecipeCheckbox
            name="close_all_gaps"
            value={CloseAllGaps.YES}
            intlId="CAG_YES"
            onChange={() => change("close_all_gaps_value", "")}
            ariaLabelledBy="close-all-gaps-label"
          />

          {close_all_gaps == CloseAllGaps.MAINTAIN ? (
            <RecipeCheckbox
              name="close_all_gaps"
              value={CloseAllGaps.MAINTAIN}
              intlId="CAG_MAINTAIN"
              onChange={() => change("close_all_gaps_value", "")}
              ariaLabelledBy="close-all-gaps-label"
            />
          ) : null}

          <RecipeCheckbox
            name="close_all_gaps"
            value={CloseAllGaps.LEAVE_SPACE}
            intlId="CAG_LEAVE_SPACE"
            onChange={() => change("close_all_gaps_value", "")}
            ariaLabelledBy="close-all-gaps-label"
          />

          <Field
            name="close_all_gaps_value"
            component={CloseAllGapsTextArea}
            disabled={close_all_gaps != CloseAllGaps.LEAVE_SPACE}
          />
          <br />

          {close_all_gaps == CloseAllGaps.LEAVE_SPACE ? (
            <div>
              <Label id="close-all-gaps-leave-space-label" readOnly>
                <FormattedMessage id="CAG_LEAVE_SPACE_FOR" />
              </Label>

              <FieldArray
                key={close_all_gaps}
                name="close_all_gaps_spaces"
                component={RecipeCloseAllGapsSpaces}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function CloseAllGapsTextArea(formProps: WrappedFieldProps & { disabled: boolean }) {
  const intl = useIntl();

  return (
    <TextArea
      {...formProps.input}
      id="close-all-gaps-input"
      aria-labelledby={`close_all_gaps-value-${CloseAllGaps.LEAVE_SPACE}`}
      disabled={formProps.disabled}
      maxLength={256}
      placeholder={intl.formatMessage({ id: "CAG_LEAVE_SPACE_ADD_COMMENT" })}
      className="sm:tw-w-3/4"
    />
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";

import { translateRxOption } from "~/common/prescription";
import { Label } from "~/components/ui/label";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

import { PatientCardElasticsTeethItems } from "./patient-card-teeth-items";

export function PatientCardElastics({
  user,
  prescription,
}: {
  user: TUser;
  prescription: TPrescription;
}) {
  const { elastics_recoil_right, elastics_recoil_left, elastics_options } = prescription;
  const elastics = Object.keys(elastics_options).map((i) => parseInt(i));

  return (
    <div>
      <Label readOnly>
        <FormattedMessage id="HEADER_ELASTICS" />
      </Label>

      <div className="row">
        <LabelledValue  className="form-group col-md-4" label={<FormattedMessage id="ELASTICS_RIGHT" />}>
          <FormattedMessage
            id={translateRxOption("elastics_recoil_right", elastics_recoil_right)}
          />
        </LabelledValue>

        <LabelledValue className="form-group col-md-4"label={<FormattedMessage id="ELASTICS_LEFT" />}>
          <FormattedMessage
            id={translateRxOption("elastics_recoil_left", elastics_recoil_left)}
          />
        </LabelledValue>
      </div>

      {elastics.length > 0 ? (
        <PatientCardElasticsTeethItems
          label="BUTTONS_AND_HOOKS"
          name="elastics_options"
          notation={user.preferences.dental_notation}
          prescription={prescription}
        />
      ) : null}
    </div>
  );
}

import clsx from "clsx";
import React from "react";

import { Label, type LabelProps } from "./label";

export function LabelledRadioGroup({
  label,
  labelProps,
  required,
  className,
  children,
}: React.PropsWithChildren<{
  label: React.ReactChild;
  labelProps?: Pick<LabelProps, "id" | "tooltip" | "size">;
  required?: boolean;
  className?: string;
}>) {
  return (
    <fieldset
      role="radiogroup"
      className={clsx("tw-space-y-1", className)}
      aria-required={required}
    >
      <Label as="legend" required={required} {...labelProps}>
        {label}
      </Label>

      <div className="tw-mb-0 tw-mt-1 tw-flex tw-flex-col tw-gap-1.5">{children}</div>
    </fieldset>
  );
}

type RadioInputProps = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "className" | "style" | "type"
>;

export function LabelledRadio({
  label,
  align = "center",
  ...props
}: RadioInputProps & { label: React.ReactChild; align?: "start" | "center" }) {
  return (
    <label
      className={clsx("tw-flex tw-gap-2", {
        "tw-items-center": align == "center",
        "tw-items-start": align == "start",
      })}
    >
      <input {...props} className="tw-m-0 focus-visible:tw-outline-offset-[3px]" type="radio" />
      {label}
    </label>
  );
}

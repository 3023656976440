import React from "react";
import { FormattedMessage } from "react-intl";

import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";

export function PatientCardClinic({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="clinic-section">
        <FormattedMessage id="HEADER_CLINIC" />
      </h3>

      <LabelledValue label={<FormattedMessage id="clin_view.name.placeholder" />}>
        {patient.clinic?.title ?? <FormattedMessage id="None" />}
      </LabelledValue>
    </div>
  );
}

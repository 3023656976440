import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { getClinic, setAddress, unlinkClinic } from "~/actions/clinics";
import { remoteLog } from "~/common/logging";
import { Button } from "~/components/ui/button";
import { LabelledValue } from "~/components/ui/labelled-value";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";
import type { TClinic } from "~/types/clinic";

import { TranslationMap } from "./schedule";

const mapStateToProps = (state: RootState) => {
  return {
    clinic: state.clinic,
    clinics: state.clinics,
  };
};

const mapDispatchToProps = {
  getClinic,
  unlinkClinic,
};

type ClinicProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    clinic_id: string;
  }>;

type ClinicState = {
  schedule: Partial<TClinic["schedule_json"]>;
  isLoading: boolean;
};

class Clinic extends Component<ClinicProps, ClinicState> {
  constructor(props: ClinicProps) {
    super(props);
    this.state = {
      schedule: props.clinic.schedule_json || {},
      isLoading: false,
    };
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "clinic_page");
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { clinic_id } = this.props.match.params;
    this.props.getClinic(clinic_id);
    setDocumentTitle(this.props.intl.formatMessage({ id: "clin_view.name" }) + " " + clinic_id);
  }

  componentDidUpdate(prevProps: ClinicProps) {
    const { clinic, clinics } = this.props;
    if (clinic !== prevProps.clinic) {
      this.setState({ schedule: { ...clinic.schedule_json } });
      this.setState({ isLoading: false });
    }
    if (prevProps.clinics.length !== clinics.length) {
      this.props.history.push("/pages/doctor/clinics");
    }
  }

  unlinkClinicButton() {
    if (window.confirm(this.props.intl.formatMessage({ id: "clin_view.confirm" }))) {
      this.props.unlinkClinic(this.props.match.params.clinic_id);
    }
  }

  render() {
    const { schedule } = this.state;

    return (
      <Layout>
        <div className="row">
          <div className="col-md-8">
            <Portlet as="main">
              <PortletTitle iconClassName="icon-book-open">
                <FormattedMessage id="clin_view.name" />
              </PortletTitle>

              {this.state.isLoading ? (
                <Loader />
              ) : (
                <div className="portlet-body">
                  <h3 className="block">
                    <FormattedMessage id="clin_view.header" />
                  </h3>

                  <LabelledValue label={<FormattedMessage id="clin_view.name" />}>
                    {this.props.clinic.title}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.email" />}>
                    {this.props.clinic.email}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.address" />}>
                    {setAddress(this.props.clinic)}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.zip" />}>
                    {this.props.clinic.zip_code}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.phone" />}>
                    {this.props.clinic.phone}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.contacts" />}>
                    {this.props.clinic.contact}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.logistic_phone" />}>
                    {this.props.clinic.logistic_phone}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.logistic_contact" />}>
                    {this.props.clinic.logistic_contact}
                  </LabelledValue>

                  <LabelledValue label={<FormattedMessage id="clin_view.schedule.schedule" />}>
                    {Object.keys(schedule).map((weekday) => (
                      <div key={weekday} style={{ marginBottom: "10px" }}>
                        <FormattedMessage id={`clin_view.schedule.${TranslationMap[weekday]}`} />:{" "}
                        {`${schedule[weekday].from} - ${schedule[weekday].to}`}
                      </div>
                    ))}
                  </LabelledValue>

                  <div className="form-actions">
                    <Button onClick={() => this.unlinkClinicButton()}>
                      <FormattedMessage id="clin_view.detach" />
                    </Button>
                  </div>
                </div>
              )}
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(Clinic));

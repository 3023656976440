import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";
import { change, formValueSelector } from "redux-form";

import { Material } from "~/common/prescription";
import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { useAppDispatch, useAppSelector } from "~/store";
import type { ValueOf } from "~/types/common";

const selector = formValueSelector("correction");

export default function ImpressionScanOptions() {
  const material = useAppSelector(
    (state) => selector(state, "material") as ValueOf<typeof Material> | null | undefined,
  );
  const dispatch = useAppDispatch();

  type TOption = { value: number; intlId: MessageDescriptor["id"] };
  const options: TOption[] = [
    { value: Material.SCAN, intlId: "C_SCAN" },
    { value: Material.IMPRESSIONS, intlId: "C_IMPRESSIONS" },
  ];

  return (
    <div className="tw-mb-[15px] tw-w-full">
      <LabelledRadioGroup
        label={<FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />}
        labelProps={{ id: "validation-material" }}
        required
      >
        {options.map((option) => (
          <LabelledRadio
            key={option.value}
            label={<FormattedMessage id={option.intlId} />}
            name="material"
            value={option.value}
            checked={material == option.value}
            onChange={(e) => dispatch(change("correction", "material", e.target.value))}
          />
        ))}
      </LabelledRadioGroup>

      <AnimatePresence>{material == Material.SCAN ? <ScanUploadWarning /> : null}</AnimatePresence>
    </div>
  );
}

function ScanUploadWarning() {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <div className="tw-overflow-hidden">
      <motion.p
        className="tw-m-0 tw-font-semibold tw-text-[#ff9c05f7]"
        {...(shouldReduceMotion == true ? {} : animationProps)}
      >
        <span className="tw-block tw-h-4" />
        <FormattedMessage id="SCAN_UPLOAD_SCAN_WARNING" />
      </motion.p>
    </div>
  );
}

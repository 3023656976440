import * as React from "react";

import { Input, type InputProps } from "./input";
import { Label, type LabelProps } from "./label";

type LabelledInputProps = Omit<InputProps, "id" | "className" | "style"> & {
  id: string;
  label: React.ReactChild;
  labelProps?: Pick<LabelProps, "id" | "tooltip">;
};

export function LabelledInput({ label, labelProps, ...props }: LabelledInputProps) {
  return (
    <div className="form-group">
      <Label {...labelProps} htmlFor={props.id} required={props.required}>
        {label}
      </Label>

      <Input {...props} />
    </div>
  );
}

import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { getFormValues, isDirty } from "redux-form";
import invariant from "tiny-invariant";

import { eraseStateProp } from "~/actions/dashboard";
import { getPatientId } from "~/actions/get_patient_id";
import { patientIsSavingSuccess, type TCreatePatientInstructions, updateAndSubmitPatient, updatePatient } from "~/actions/post_patient";
import { FileType, RxType } from "~/common/constants";
import { is3DPROCourse, isRetainersCourse, Status } from "~/common/courses";
import { convertToJSONFullRx } from "~/common/instructions";
import { remoteLog } from "~/common/logging";
import { assertIsPatient, getLastCorrection, isPatient } from "~/common/patient";
import { canOrderTestPlastic } from "~/common/user";
import { scrollTo } from "~/components/common/ScrollToElement/scrollTo";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import { getCourseInstallment } from "~/slices/course-installment";
import { createInstructionsSuccess } from "~/slices/instructions";
import type { RootState } from "~/store";

import LinksCT, { type ReduxFormLinks } from "./addLinks";
import ImpressionScanOptions from "./impression_scan_options";
import PatientUpdateInstructionsNewImages from "./patient_new/patient_new_instructions_images";
import {
  PATIENT_NEW_REQUIRED_FIELDS,
  type PatientUpdateFormValues,
  type TPatientNewRequiredField,
  validateArch,
  validateClinicAndPaymentMethodAndCourse,
  validateEmail,
  validateLinks,
  validateMaterial,
  validateOcclusalPlane,
  validatePatientName,
  validatePatientPayerNameForChildrenOrTeenCourse,
  validatePayer,
  validatePaymentOption,
  validatePhotos,  validateTestPlastic,
  validateVerticalOverlapComment,
} from "./patient_new_validation";
import PatientUpdateCourse from "./patient_update/patient_update_course";
import PatientUpdateDoctorInfo from "./patient_update/patient_update_doctor_info";
import PatientUpdateInstructionsNewMedia from "./patient_update/patient_update_instructions_new_media";
import PatientUpdateInstructionsUploadCt from "./patient_update/patient_update_instructions_upload_ct";
import PatientUpdatePatientInfo from "./patient_update/patient_update_patient_info";
import PatientUpdatePayment from "./patient_update/patient_update_payment";
import RecipeFormShortRX from "./recipe_redux_form_short_rx";
import RouteLeavingGuard from "./route_leaving_guard";
import RecipeFormSmile from "./smile_recipe_redux_form";

const ScrollToField = (toId: string) => {
  scrollTo({ id: toId, duration: 1500 });
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    doctor_id: state.user.account_id,
    patient: state.patient,
    patientSaving: state.patientSaving,
    instructions: state.instructions,
    media: state.media,
    media_s3: state.media_s3,
    formValues: getFormValues("correction")(state) as PatientUpdateFormValues,
    links: getFormValues("links")(state) as ReduxFormLinks | undefined,
    dirtyCorrection: isDirty("correction")(state),
    dirtyLinks: isDirty("links")(state),
  };
};

const mapDispatchToProps = {
  updateAndSubmitPatient,
  getPatientId,
  patientIsSaving: patientIsSavingSuccess,
  initInstrucrions: createInstructionsSuccess,
  eraseStateProp,
  updatePatient,
  getCourseInstallment,
};

type PatientUpdateProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{ patient_id: string; }>;

type PatientUpdateState = {
  isSubmitPending: boolean;
  isDraftPending: boolean;
  values: Record<string, unknown>;
  isDirty: boolean;
  submit: boolean;
};

class PatientUpdate extends Component<PatientUpdateProps, PatientUpdateState> {
  constructor(props: PatientUpdateProps) {
    super(props);
    this.state = {
      isSubmitPending: false,
      isDraftPending: false,
      values: {},
      isDirty: false,
      submit: false,
    };
    this.submitButton = this.submitButton.bind(this);
    this.submitSaveButton = this.submitSaveButton.bind(this);
    this.setDirty = this.setDirty.bind(this);
    this.fm = this.fm.bind(this);
    this.renderRx = this.renderRx.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'patient_update');
  }

  submitButton() {
    assertIsPatient(this.props.patient);
    if ($("#submit-pacient-btn").attr("disabled")) return;

    const { user, patient, instructions, formValues } = this.props;
    const course_id = instructions.course_id ?? patient.course.course_id;
    const lastCorrection = getLastCorrection(patient);
    const rxTypeId = lastCorrection.prescription?.rx_type_id ?? patient.rx_type_id;
    const emptyFields: TPatientNewRequiredField[] = [];

    const linksArray = this.props.links?.links ?? [];
    const links = linksArray.flatMap(link => Object.values(link));

    const isPatientNameValid = validatePatientName(instructions, emptyFields, { ignoreDoctorId: true });
    const isMaterialValid = validateMaterial(formValues, emptyFields);
    const isVerticalOverlapCommentValid = validateVerticalOverlapComment(formValues, emptyFields);
    const isTestPlasticValid = canOrderTestPlastic(user) ? validateTestPlastic(instructions, emptyFields) : true;
    const isEmailValid = validateEmail(instructions, emptyFields);
    const isPayerValid = validatePayer(instructions, emptyFields);
    const isPaymentOptionValid = validatePaymentOption(instructions, emptyFields);
    const areClinicAndPaymentMethodAndCourseValid = validateClinicAndPaymentMethodAndCourse(
      instructions,
      emptyFields,
    );
    const areLinksValid = validateLinks(links, emptyFields);
    const isArchValid = validateArch(formValues, emptyFields);
    const isPatientPayerNameValidForChildrenOrTeenCourse =
      validatePatientPayerNameForChildrenOrTeenCourse(instructions, emptyFields);
    const isOcclusalPlaneValid = validateOcclusalPlane(instructions, formValues, emptyFields);
    const arePhotosValid = validatePhotos(instructions, this.props.media, emptyFields, patient);
    const isBirthDateValid = !instructions.bdate || instructions.bdate !== "Invalid date";

    const areRequiredFieldsValid = (
      isPatientNameValid &&
      isMaterialValid &&
      isVerticalOverlapCommentValid &&
      isTestPlasticValid &&
      isEmailValid &&
      isPayerValid &&
      isPaymentOptionValid &&
      areClinicAndPaymentMethodAndCourseValid &&
      areLinksValid &&
      isArchValid &&
      isPatientPayerNameValidForChildrenOrTeenCourse &&
      isOcclusalPlaneValid &&
      arePhotosValid &&
      isBirthDateValid
    );

    const areConditionsSatisfied = (
      areRequiredFieldsValid ||
      lastCorrection.index > 0 ||
      (isRetainersCourse(course_id) && !lastCorrection.order_options.full_edit) ||
      (lastCorrection.index == 0 && lastCorrection.approved_plan_id)
    );

    if (!areConditionsSatisfied) {
      const scrollToFields = PATIENT_NEW_REQUIRED_FIELDS.filter((f) => emptyFields.includes(f));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ScrollToField(scrollToFields[0]!);
      return;
    }

    this.setState({ isSubmitPending: true, submit: true });

    if (patient.status == Status.UNFILLED_CASE) {
      invariant(formValues, 'expected formValues to be defined');
      const instructionsCopy: TCreatePatientInstructions = { ...instructions };

      instructionsCopy.prescription = convertToJSONFullRx(formValues);
      instructionsCopy.prescription.rx_type_id = rxTypeId;
      instructionsCopy.links = links;

      this.props.updateAndSubmitPatient(patient.patient_id, {
        instructions: instructionsCopy,
        media: this.props.media,
        media_s3: this.props.media_s3,
      });
    } else if (
      patient.course.correction.length > 1 &&
      lastCorrection.order_options.can_edit_prescription &&
      lastCorrection.order_options.full_edit
    ) {
      invariant(formValues, 'expected formValues to be defined');
      const instructionsCopy: TCreatePatientInstructions = { ...instructions };

      instructionsCopy.prescription = convertToJSONFullRx(formValues);
      instructionsCopy.prescription.rx_type_id = rxTypeId;
      instructionsCopy.links = links;

      this.props.updatePatient(patient.patient_id, {
        instructions: instructionsCopy,
        media: this.props.media,
        media_s3: this.props.media_s3,
      });
    } else if (!lastCorrection.order_options.can_edit_prescription) {
      const instructionsCopy: TCreatePatientInstructions = { ...instructions };
      instructionsCopy.links = links;

      this.props.updatePatient(patient.patient_id, {
        instructions: instructionsCopy,
        media: this.props.media,
        media_s3: this.props.media_s3,
      });
    } else {
      invariant(formValues, 'expected formValues to be defined');
      const instructionsCopy: TCreatePatientInstructions = { ...instructions };

      instructionsCopy.prescription = convertToJSONFullRx(formValues);
      instructionsCopy.prescription.rx_type_id = rxTypeId;
      instructionsCopy.links = links;

      this.props.updatePatient(patient.patient_id, {
        instructions: instructionsCopy,
        media: this.props.media,
        media_s3: this.props.media_s3,
      });
    }
  }

  submitSaveButton() {
    assertIsPatient(this.props.patient);
    invariant(this.props.formValues, 'expected formValues to be defined');

    _paq.push(["trackEvent", "Update patient", "Save as a draft"]);
    if ($("#save-pacient-btn").attr("disabled")) return;

    const { patient, instructions, formValues } = this.props;
    const lastCorrection = getLastCorrection(patient);
    const rxTypeId = lastCorrection.prescription?.rx_type_id ?? patient.rx_type_id;
    const emptyFields: TPatientNewRequiredField[] = [];

    const linksArray = this.props.links?.links ?? [];
    const links = linksArray.flatMap(link => Object.values(link));

    const isPatientNameValid = validatePatientName(instructions, emptyFields, { ignoreDoctorId: true });
    const isVerticalOverlapCommentValid = validateVerticalOverlapComment(formValues, emptyFields);
    const areLinksValid = validateLinks(links, emptyFields);

    if (!(isPatientNameValid && isVerticalOverlapCommentValid && areLinksValid)) {
      const scrollToFields = PATIENT_NEW_REQUIRED_FIELDS.filter((f) => emptyFields.includes(f));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ScrollToField(scrollToFields[0]!);
      return;
    }

    this.setState({ isDraftPending: true, submit: true });
    const instructionsCopy: TCreatePatientInstructions = { ...instructions };

    instructionsCopy.prescription = convertToJSONFullRx(formValues);
    instructionsCopy.prescription.rx_type_id = rxTypeId;
    instructionsCopy.links = links;

    this.props.updatePatient(patient.patient_id, {
      instructions: instructionsCopy,
      media: this.props.media,
      media_s3: this.props.media_s3,
    })
  }

  componentDidMount() {
    const { user } = this.props;
    const { patient_id } = this.props.match.params;

    window.scrollTo(0, 0);
    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    void this.props.getPatientId(Number(patient_id));
    void this.props.getCourseInstallment();

    this.props.initInstrucrions();
    this.props.eraseStateProp("media");

    setDocumentTitle(this.props.intl.formatMessage({ id: "BTN_EDIT" }) + " " + patient_id);
  }

  UNSAFE_componentWillUpdate(nextProps: PatientUpdateProps) {
    if (nextProps.patient && nextProps.patientSaving) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp("patient");
      this.props.history.push("/pages/patients");
    }
  }

  componentDidUpdate() {
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const { isDirty, submit } = this.state;
    const formIsDirty =
      !submit &&
      (isDirty ||
        dirtyCorrection ||
        dirtyLinks ||
        Object.keys(media).length >= 1 ||
        (media_s3.files && media_s3.files.length >= 1));
    if (formIsDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  };

  componentWillUnmount() {
    this.props.eraseStateProp("patient");
    window.onbeforeunload = null;
  }

  setDirty(key, data) {
    const patient_data = this.props.patient;
    if (
      data == "" ||
      (patient_data.hasOwnProperty(key) && data !== patient_data[key]) ||
      (patient_data.course.hasOwnProperty(key) &&
        data !== patient_data.course[key]) ||
      (patient_data.clinic.hasOwnProperty(key) &&
        data !== patient_data.clinic[key])
    ) {
      this.setState({ isDirty: true });
    } else {
      this.setState({ isDirty: false });
    }
  }

  fm(id: MessageDescriptor["id"]) {
    return this.props.intl.formatMessage({ id: id });
  }

  renderRx(rxTypeId, course_id) {
    if (rxTypeId === RxType.STANDARD) {
      return <RecipeFormSmile course_id={course_id} />
    } else {
      return <RecipeFormShortRX course_id={course_id} />;
    }
  }

  render() {
    if (!isPatient(this.props.patient)) {
      return (
        <Layout>
          <Loader />
        </Layout>
      );
    }

    const lastCorrection = getLastCorrection(this.props.patient);
    const isUnfilledCase = this.props.patient.status == Status.UNFILLED_CASE;
    const rxTypeId = lastCorrection.prescription ? lastCorrection.prescription.rx_type_id : this.props.patient.rx_type_id;
    const full_edit = lastCorrection.order_options.full_edit;
    const course_id = this.props.instructions.course_id ? this.props.instructions.course_id : this.props.patient.course.course_id;
    const isNotRetainersCourse = !isRetainersCourse(course_id);
    const disable_on_3d_smile_pro = isUnfilledCase && is3DPROCourse(this.props.patient.course.course_id);
    const can_edit_prescription = lastCorrection.order_options.can_edit_prescription;
    const has_medit_files = this.props.patient.s3_media && this.props.patient.s3_media.find((f) => f.file_type == FileType.MEDIT) !== undefined;

    const { isDirty, submit } = this.state;
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const formIsDirty =
      !submit &&
      (isDirty ||
        dirtyCorrection ||
        dirtyLinks ||
        Object.keys(media).length >= 1 ||
        (media_s3.files && media_s3.files.length >= 1));

    return (
      <Layout>
        <Portlet as="main">
          <div className="portlet-title">
            <div className="col-md-8 col-sm-8 col-xs-8 caption">
              <i className="icon-book-open font-green" />
              <span className="caption-subject font-green bold uppercase">
                <FormattedMessage id="BTN_EDIT" />
              </span>
            </div>
            <div
              className="col-md-4 col-sm-4 col-xs-4"
              style={{ padding: "8px 0" }}
            >
              <label>
                <span className="required mob-title" aria-required="true">
                  *{" "}
                </span>
                <i className="mob-title">
                  - <FormattedMessage id="requiredField" />
                </i>
                <span className="prescription_tooltip_show">
                  <i
                    style={{ fontSize: "12px", marginLeft: "6px" }}
                    className="icon-question"
                  />
                  <span
                    style={{
                      right: isMobile ? "-30px" : "",
                      position: "absolute",
                    }}
                    className="prescription_tooltip"
                  >
                    <FormattedMessage id="required.fields.tooltip" />
                  </span>
                </span>
              </label>
            </div>
          </div>
          <RouteLeavingGuard
            when={formIsDirty}
            shouldBlockNavigation={() => formIsDirty}
            saveChanges={isUnfilledCase ? this.submitSaveButton : this.submitButton}
            saveAsADraft={false}
          />

          <div className="portlet-body form" id="add-patient-form">
            <div className="form-body">
              <div className="alert alert-danger display-hide">
                <button className="close" data-close="alert" />
                <span id="error-msg" />
              </div>
              <div className="row">
                <div className="col-md-5">
                  <PatientUpdateDoctorInfo patient={this.props.patient} setDirty={this.setDirty} />
                </div>
                {has_medit_files === true ? (
                  <div className="col-xs-10 col-sm-5">
                    <div
                      className="alert alert-success alert-dismissible show"
                      role="alert"
                      id="upload-medit-alert-warning"
                    >
                      <FormattedMessage id="th.medit.file.upload_success" />
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              {full_edit === true ? (
                <>
                  <PatientUpdatePayment setDirty={this.setDirty} patient={this.props.patient} />
                  <PatientUpdatePatientInfo patient={this.props.patient} setDirty={this.setDirty} />
                  <PatientUpdateCourse
                    patient={this.props.patient}
                    setDirty={this.setDirty}
                    rxTypeId={rxTypeId}
                  />
                  {rxTypeId === RxType.STANDARD ? (
                    <RecipeFormSmile course_id={course_id} />
                  ) : (
                    <RecipeFormShortRX course_id={course_id} />
                  )}
                </>
              ) : null}
              {can_edit_prescription && !full_edit ? (
                <ImpressionScanOptions />
              ) : null}
              <PatientUpdateInstructionsNewMedia
                showUploadedFiles={true}
                has_medit_files={has_medit_files}
              />
              {isNotRetainersCourse ? (
                <PatientUpdateInstructionsUploadCt showUploadedFiles={true} />
              ) : null}
              {isNotRetainersCourse ? <LinksCT /> : null}
              {isUnfilledCase && isNotRetainersCourse ? (
                <PatientUpdateInstructionsNewImages />
              ) : null}
              {can_edit_prescription && !full_edit
                ? this.renderRx(rxTypeId, course_id)
                : null}
              <input type="hidden" id="doctor-id" name="doctor_id" defaultValue={264} />
            </div>

            <div className="tw-flex tw-flex-wrap tw-gap-3 tw-border-t tw-border-[#e7ecf1] tw-py-5 sm:tw-gap-2">
              <LoadingButton
                id="submit-pacient-btn"
                type="button"
                variant="primary"
                size="xl"
                className="tw-w-full sm:tw-max-w-[180px]"
                disabled={disable_on_3d_smile_pro || this.state.isDraftPending}
                onClick={() => this.submitButton()}
                isLoading={this.state.isSubmitPending}
              >
                <FormattedMessage id={isUnfilledCase ? "BTN_SUBMIT_NEW" : "BTN_SAVE"} />
              </LoadingButton>

              {isUnfilledCase ? (
                <LoadingButton
                  id="save-pacient-btn"
                  type="button"
                  variant="secondary"
                  size="xl"
                  className="tw-w-full sm:tw-max-w-[180px]"
                  disabled={this.state.isSubmitPending}
                  onClick={() => this.submitSaveButton()}
                  isLoading={this.state.isDraftPending}
                >
                  <FormattedMessage id="BTN_SAVE_DRAFT" />
                </LoadingButton>
              ) : null}
            </div>
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdate));

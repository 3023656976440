import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse, isRetainersCourse } from "~/common/courses";
import { newLineToLineBreakTag } from "~/components/common/formatText/formatText";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

import { PatientCardCanines } from "./patient-card-canines";
import { PatientCardCloseAllGaps } from "./patient-card-close-all-gaps";
import { PatientCardElastics } from "./patient-card-elastics";
import { PatientCardIncisors } from "./patient-card-incisors";
import { PatientCardMolars } from "./patient-card-molars";
import { PatientCardTeethItems } from "./patient-card-teeth-items";
import { PatientCardTreatArches } from "./patient-card-treat-arches";

export function PatientCardRecipe({
  user,
  course_id,
  prescription,
}: {
  user: TUser;
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  return (
    <div>
      <PatientCardTreatArches user={user} course_id={course_id} prescription={prescription} />

      {isRetainersCourse(course_id) ? null : (
        <>
          <PatientCardIncisors course_id={course_id} prescription={prescription} />

          {isChildrenCourse(course_id) ? null : <PatientCardCanines prescription={prescription} />}

          <PatientCardMolars course_id={course_id} prescription={prescription} />

          {isChildrenCourse(course_id) ? (
            <PatientCardTeethItems
              label="EXTRA_TEETHING"
              defaultValue={prescription.teething}
              notation={user.preferences.dental_notation}
              key={prescription.teething.join(",")}
            />
          ) : null}

          {isChildrenCourse(course_id) ? null : (
            <>
              <PatientCardCloseAllGaps user={user} prescription={prescription} />
              <PatientCardElastics user={user} prescription={prescription} />

              <PatientCardTeethItems
                key={prescription.dont_move.join(",")}
                label="EXTRA_NOMOVE"
                defaultValue={prescription.dont_move}
                notation={user.preferences.dental_notation}
              />

              <PatientCardTeethItems
                key={prescription.avoid_locks.join(",")}
                label="EXTRA_NOLOCK"
                defaultValue={prescription.avoid_locks}
                notation={user.preferences.dental_notation}
              />

              <PatientCardTeethItems
                key={prescription.extractions.join(",")}
                label="EXTRA_EXTRACT"
                defaultValue={prescription.extractions}
                notation={user.preferences.dental_notation}
              />
            </>
          )}
        </>
      )}

      <LabelledValue label={<FormattedMessage id="EXTRA_COMMENTS" />}>
        <span style={{ wordBreak: "break-word" }}>
          {prescription.comment ? (
            newLineToLineBreakTag(prescription.comment)
          ) : (
            <FormattedMessage id="None" />
          )}
        </span>
      </LabelledValue>
    </div>
  );
}

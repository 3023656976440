import * as React from "react";

import { Label, type LabelProps } from "./label";

export function LabelledValue({
  label,
  labelProps,
  className,
  children,
}: React.PropsWithChildren<{
  label: React.ReactChild;
  labelProps?: Pick<LabelProps, "italic" | "semibold">;
  className?: string;
}>) {
  return (
    <div className={className}>
      <Label readOnly {...labelProps}>
        {label}
      </Label>

      <div className="tw-mb-5">{children}</div>
    </div>
  );
}

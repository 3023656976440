import { animateScroll } from "./animateScroll";

export function scrollTo({ id, duration = 3000 }: { id: string; duration: number }) {
  const initialPosition = window.scrollY;
  const element = id ? document.getElementById(id) : null;

  if (!element) {
    logError(id);
    return;
  }

  animateScroll({
    targetPosition: getElementPosition(element),
    initialPosition,
    duration,
  });
}

function logError(id: string) {
  console.error(`Invalid element id: "${id}", are you sure you've provided element id?`);
}

function getElementPosition(element: HTMLElement) {
  return element.offsetTop;
}

import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/react-datepicker.css";

import moment, { type Moment } from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { FormattedMessage, injectIntl, type IntlShape, useIntl } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { Gender, Payer } from "~/common/constants";
import { isChildrenCourse, isTeenCourse } from "~/common/courses";
import { DATE_FORMAT, formatDatetime } from "~/common/datetime";
import { remoteLog } from "~/common/logging";
import { Label } from "~/components/ui/label";
import { LabelledInput } from "~/components/ui/labelled-input";
import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { addInstructions, removeInstructions } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { SetDirtyFn } from "~/types/patient-new";

const mapStateToProps = (state: RootState) => {
  return {
    instructions: state.instructions,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstruction: removeInstructions,
};

type PatientNewPatientInfoProps = PropsFromRedux & { intl: IntlShape; setDirty: SetDirtyFn };

type PatientNewPatientInfoState = {
  startDate: Moment | null;
};

class PatientNewPatientInfo extends Component<
  PatientNewPatientInfoProps,
  PatientNewPatientInfoState
> {
  constructor(props: PatientNewPatientInfoProps) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.addMiddleName = this.addMiddleName.bind(this);
    this.selectSex = this.selectSex.bind(this);
    this.addBdate = this.addBdate.bind(this);
    this.addPayerFirstName = this.addPayerFirstName.bind(this);
    this.addPayerLastName = this.addPayerLastName.bind(this);
    this.addPayerMiddleName = this.addPayerMiddleName.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_new_patient_info");
  }

  addFirstName(data: string) {
    this.props.addInstruction({ first_name: data });
    this.props.setDirty("first_name", data);
  }

  addLastName(data: string) {
    this.props.addInstruction({ last_name: data });
    this.props.setDirty("last_name", data);
  }

  addMiddleName(data: string) {
    this.props.addInstruction({ middle_name: data });
    this.props.setDirty("middle_name", data);
  }

  addPayerFirstName(data: string) {
    this.props.addInstruction({ payer_first_name: data });
    this.props.setDirty("payer_first_name", data);
  }

  addPayerLastName(data: string) {
    this.props.addInstruction({ payer_last_name: data });
    this.props.setDirty("payer_last_name", data);
  }

  addPayerMiddleName(data: string) {
    this.props.addInstruction({ payer_patronymic: data });
    this.props.setDirty("payer_patronymic", data);
  }

  selectSex(data: string) {
    this.props.addInstruction({ sex: parseInt(data) });
    this.props.setDirty("sex", parseInt(data));
  }

  addBdate(data: Moment) {
    if (data) {
      this.setState({ startDate: data });
      this.props.addInstruction({ bdate: moment(data).format("YYYY-MM-DD") });
      this.props.setDirty("bdate", moment(data).format("YYYY-MM-DD"));
    }
  }

  addEmail(data: string) {
    this.props.addInstruction({ email: data });
    this.props.setDirty("email", data);
  }

  render() {
    const { intl, instructions } = this.props;

    const isPayerNameRequired =
      isChildrenCourse(instructions.course_id) || isTeenCourse(instructions.course_id);

    return (
      <div>
        <h3 className="block" id="patient-block-label" style={{ fontWeight: "900" }}>
          <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
        </h3>

        <div className="row" id="patient-block-body">
          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_LAST_NAME" />}
              labelProps={{ id: "validation-last_name" }}
              id="patient-lastname-value"
              required
              type="text"
              name="last_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_LAST_NAME_PLACEHOLDER" })}
              onChange={(e) => this.addLastName(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_FIRST_NAME" />}
              labelProps={{ id: "validation-first_name" }}
              id="patient-firstname-value"
              required
              type="text"
              name="first_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_FIRST_NAME_PLACEHOLDER" })}
              onChange={(e) => this.addFirstName(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <LabelledInput
              label={<FormattedMessage id="PAT_INFO_MIDDLE_NAME" />}
              labelProps={{ id: "validation-patronymic_name" }}
              id="patient-middlename-label"
              type="text"
              name="patronymic_name"
              placeholder={intl.formatMessage({ id: "PAT_INFO_MIDDLE_NAME_PLACEHOLDER" })}
              onChange={(e) => this.addMiddleName(e.target.value)}
            />
          </div>
        </div>

        {intl.locale == "ru" && instructions.payer_id == Payer.PATIENT ? (
          <div>
            <LabelledInput
              label={<FormattedMessage id="PAT_EMAIL" />}
              labelProps={{ id: "validation-email" }}
              id="email"
              required
              name="email"
              placeholder={intl.formatMessage({ id: "PAT_INFO_EMAIL_PLACEHOLDER" })}
              onChange={(e) => this.addEmail(e.target.value)}
              maxLength={256}
            />

            <div className="row">
              <div className="col-md-8">
                <Label semibold readOnly>
                  <FormattedMessage id="another.payer.person" />
                </Label>
              </div>
            </div>

            <div className="row" id="patient-block-body">
              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_LAST_NAME" />}
                  id="payer-lastname-value"
                  required={isPayerNameRequired}
                  name="payer_last_name"
                  placeholder={intl.formatMessage({ id: "PAYER_LAST_NAME_PLACEHOLDER" })}
                  onChange={(e) => this.addPayerLastName(e.target.value)}
                  maxLength={64}
                />
              </div>

              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_FIRST_NAME" />}
                  id="payer-firstname-value"
                  required={isPayerNameRequired}
                  name="payer_first_name"
                  placeholder={intl.formatMessage({ id: "PAYER_FIRST_NAME_PLACEHOLDER" })}
                  onChange={(e) => this.addPayerFirstName(e.target.value)}
                  maxLength={64}
                />
              </div>

              <div className="col-md-4">
                <LabelledInput
                  label={<FormattedMessage id="PAYER_MIDDLE_NAME" />}
                  id="payer-middlename-value"
                  required={isPayerNameRequired}
                  name="payer_patronymic"
                  placeholder={intl.formatMessage({ id: "PAYER_MIDDLE_NAME_PLACEHOLDER" })}
                  onChange={(e) => this.addPayerMiddleName(e.target.value)}
                  maxLength={64}
                />
              </div>
            </div>
          </div>
        ) : null}

        <GenderRadioGroup onChange={(e) => this.selectSex(e.target.value)} />

        <BirthDateInput
          selected={this.state.startDate}
          onSelect={(e) => this.addBdate(e)}
          onBlur={() => this.props.removeInstruction({ bdate: null })}
        />
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNewPatientInfo));

export function GenderRadioGroup({
  sex,
  onChange,
}: {
  sex?: Gender | null;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <LabelledRadioGroup
      className="form-group"
      label={<FormattedMessage id="HEADER_SEX" />}
      labelProps={{ id: "validation-sex" }}
    >
      <LabelledRadio
        label={<FormattedMessage id="SEX_F" />}
        name="sex"
        defaultValue={Gender.FEMALE}
        defaultChecked={sex == Gender.FEMALE}
        onChange={onChange}
      />

      <LabelledRadio
        label={<FormattedMessage id="SEX_M" />}
        name="sex"
        defaultValue={Gender.MALE}
        defaultChecked={sex == Gender.MALE}
        onChange={onChange}
      />
    </LabelledRadioGroup>
  );
}

export function BirthDateInput({
  selected,
  onSelect,
  onBlur,
}: {
  selected: Moment | null;
  onSelect(value: Moment): void;
  onBlur(): void;
}) {
  const intl = useIntl();

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <Label id="patient-bdate-label" htmlFor="patient-bdate-value">
            <FormattedMessage id="HEADER_BDATE" />
          </Label>

          <DatePicker
            locale={intl.locale}
            className="form-control max-sm:tw-text-base"
            id="patient-bdate-value"
            dateFormat={DATE_FORMAT}
            placeholderText={DATE_FORMAT}
            minDate={moment("01-01-1900", DATE_FORMAT)}
            openToDate={moment("01-01-1985", DATE_FORMAT)}
            selected={selected}
            onSelect={onSelect}
            onChange={() => {}} // NOTE: TypeScript
            onChangeRaw={(e) => formatDatetime(e)}
            onBlur={(e) => {
              if (e.target.value.length < 10) {
                e.target.value = "";
                onBlur();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";
import { Field, type WrappedFieldProps } from "redux-form";

import { isChildrenCourse } from "~/common/courses";
import {
  IncisorsLowerHorizontalOverlap,
  IncisorsUpperHorizontalOverlap,
  IncisorsVerticalOverlap,
  Midline,
  Overjet,
} from "~/common/prescription";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { TextArea } from "~/components/ui/textarea";
import type { TCourse } from "~/types/patient";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

import { RecipeCheckbox } from "./recipe-shared";

export function RecipeIncisors({
  course_id,
  vertical_overlap,
  midline,
}: {
  course_id: TCourse["course_id"] | null | undefined;
  vertical_overlap: TPrescriptionReduxForm["vertical_overlap"] | undefined;
  midline: TPrescriptionReduxForm["midline"] | undefined;
}) {
  return (
    <div id="incisors-section">
      <h4 className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="HEADER_INCISORS" />
      </h4>

      <VerticalOverlapOptions vertical_overlap={vertical_overlap} />

      {isChildrenCourse(course_id) ? null : (
        <>
          <HorizontalOverlapOptions />
          <OverjetOptions />
          <DisplacementLowerOptoins />
          <MidlineOptions midline={midline} />
        </>
      )}
    </div>
  );
}

function VerticalOverlapOptions({
  vertical_overlap,
}: {
  vertical_overlap: TPrescriptionReduxForm["vertical_overlap"] | undefined;
}) {
  return (
    <>
      <div className="form-group" style={{ marginBottom: 20 }}>
        <Label id="incisors-vert-overlap-label" readOnly>
          <FormattedMessage id="INCISORS_VO" />
        </Label>

        <div className="checkbox-list checkbox_list">
          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.MAINTAIN}
            intlId="VO_MAINTAIN"
            ariaLabelledBy="incisors-vert-overlap-label"
          />

          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.DECREASE}
            intlId="VO_DECREASE"
            ariaLabelledBy="incisors-vert-overlap-label"
          />

          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.INCREASE}
            intlId="VO_INCREASE"
            ariaLabelledBy="incisors-vert-overlap-label"
          />
        </div>
      </div>

      {vertical_overlap == IncisorsVerticalOverlap.DECREASE ||
      vertical_overlap == IncisorsVerticalOverlap.INCREASE ? (
        <div className="form-group">
          <Label
            htmlFor="vertical_overlap_comment"
            id="validation-vertical_overlap_comment"
            required
          >
            <FormattedMessage id="VO_COMMENT" />
          </Label>

          <Field name="vertical_overlap_comment" component={VerticalOverlapCommentTextArea} />
        </div>
      ) : null}
    </>
  );
}

function VerticalOverlapCommentTextArea(formProps: WrappedFieldProps) {
  return <TextArea {...formProps.input} id="vertical_overlap_comment" className="sm:tw-w-2/3" />;
}

function HorizontalOverlapOptions() {
  return (
    <div>
      <Label id="incisors-horiz-overlap-label" readOnly>
        <FormattedMessage id="INCISORS_HO" />
      </Label>

      <div className="row">
        <div className="form-group col-md-4" style={{ marginBottom: "20px" }}>
          <Label id="incisors-upper-horiz-overlap-label" readOnly>
            <FormattedMessage id="INCISORS_HO_U" />
          </Label>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.MAINTAIN}
              intlId="HO_U_MAINTAIN"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.RETROCLINATE}
              intlId="HO_U_RETROCLINATE"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.PROCLINATE}
              intlId="HO_U_PROCLINATE"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />
          </div>
        </div>

        <div className="form-group col-md-4">
          <Label id="incisors-lower-horiz-overlap-label" readOnly>
            <FormattedMessage id="INCISORS_HO_L" />
          </Label>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.MAINTAIN}
              intlId="HO_L_MAINTAIN"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.RETROCLINATE}
              intlId="HO_L_RETROCLINATE"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.PROCLINATE}
              intlId="HO_L_PROCLINATE"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

function OverjetOptions() {
  return (
    <div className="form-group">
      <Label id="overjet-label" readOnly>
        <FormattedMessage id="OVERJET" />
      </Label>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="overjet"
          value={Overjet.MAINTAIN}
          intlId="OJ_MAINTAIN"
          ariaLabelledBy="overjet-label"
        />

        <RecipeCheckbox
          name="overjet"
          value={Overjet.CONTACT}
          intlId="OJ_CONTACT"
          ariaLabelledBy="overjet-label"
        />

        <RecipeCheckbox
          name="overjet"
          value={Overjet.MAINTAIN_LARGE}
          intlId="OJ_MAINTAIN_LARGE"
          ariaLabelledBy="overjet-label"
        />
      </div>
    </div>
  );
}

function DisplacementLowerOptoins() {
  return (
    <div className="form-group">
      <Label id="displacement_lower-heading" readOnly>
        <FormattedMessage id="DISPLACEMENT_LOWER" />
      </Label>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="displacement_lower"
          value={Number(true)}
          intlId="bool.true"
          ariaLabelledBy="displacement_lower-heading"
        />

        <RecipeCheckbox
          name="displacement_lower"
          value={Number(false)}
          intlId="bool.false"
          ariaLabelledBy="displacement_lower-heading"
        />
      </div>
    </div>
  );
}

function MidlineOptions({ midline }: { midline: TPrescriptionReduxForm["midline"] | undefined }) {
  return (
    <div className="form-group">
      <Label id="validation-midline" readOnly>
        <FormattedMessage id="MIDLINE" />
      </Label>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="midline"
          value={Midline.MAINTAIN}
          intlId="ML_MAINTAIN"
          ariaLabelledBy="validation-midline"
        />

        <RecipeCheckbox
          name="midline"
          value={Midline.IMPROVE}
          intlId="ML_IMPROVE"
          ariaLabelledBy="validation-midline"
        />

        <div className="form-inline">
          <Field
            name="midline_improve_by"
            disabled={midline !== Midline.IMPROVE}
            component={MidlineImproveByInput}
          />
        </div>
      </div>
    </div>
  );
}

function MidlineImproveByInput(formProps: WrappedFieldProps & { disabled: boolean }) {
  return (
    <Input
      {...formProps.input}
      aria-labelledby={`midline-value-${Midline.IMPROVE}`}
      maxLength={512}
      className="tw-w-[32ch]"
      disabled={formProps.disabled}
    />
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse } from "~/common/courses";
import {
  IncisorsVerticalOverlap,
  Midline,
  Overjet,
  translateRxOption,
} from "~/common/prescription";
import { Label } from "~/components/ui/label";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";

export function PatientCardIncisors({
  course_id,
  prescription,
}: {
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  const {
    upper_horizontal_overlap,
    lower_horizontal_overlap,
    displacement_lower,
    midline,
    midline_improve_by,
  } = prescription;

  return (
    <div>
      <h4 id="incisors-section" className="block" style={{ fontWeight: "500" }}>
        <FormattedMessage id="HEADER_INCISORS" />
      </h4>

      <VerticalOverlapInfo prescription={prescription} />

      {isChildrenCourse(course_id) ? null : (
        <>
          {upper_horizontal_overlap != null || lower_horizontal_overlap != null ? (
            <HorizontalOverlapInfo prescription={prescription} />
          ) : null}

          <OverjetInfo prescription={prescription} />

          <LabelledValue label={<FormattedMessage id="DISPLACEMENT_LOWER" />}>
            <FormattedMessage
              id={translateRxOption("displacement_lower", `${displacement_lower}`)}
            />
          </LabelledValue>

          <LabelledValue label={<FormattedMessage id="MIDLINE" />}>
            <FormattedMessage id={translateRxOption("midline", midline)} />

            {midline == Midline.IMPROVE ? (
              <p className="tw-ml-5 tw-pt-4">{midline_improve_by}</p>
            ) : null}
          </LabelledValue>
        </>
      )}
    </div>
  );
}

function VerticalOverlapInfo({
  prescription: { vertical_overlap, vertical_overlap_comment },
}: {
  prescription: TPrescription;
}) {
  return (
    <div>
      <LabelledValue label={<FormattedMessage id="INCISORS_VO" />}>
        <FormattedMessage id={translateRxOption("vertical_overlap", vertical_overlap)} />
      </LabelledValue>

      {vertical_overlap != IncisorsVerticalOverlap.MAINTAIN && vertical_overlap_comment ? (
        <LabelledValue
          className="tw-ml-5"
          label={<FormattedMessage id="VO_COMMENT" />}
          labelProps={{ italic: true, semibold: true }}
        >
          {vertical_overlap_comment}
        </LabelledValue>
      ) : null}
    </div>
  );
}

function HorizontalOverlapInfo({
  prescription: { upper_horizontal_overlap, lower_horizontal_overlap },
}: {
  prescription: TPrescription;
}) {
  return (
    <div>
      <Label id="incisors-horiz-overlap-label" readOnly>
        <FormattedMessage id="INCISORS_HO" />
      </Label>

      <div className="row">
        <LabelledValue
          className="form-group col-md-4"
          label={<FormattedMessage id="INCISORS_HO_U" />}
        >
          <FormattedMessage
            id={translateRxOption("upper_horizontal_overlap", upper_horizontal_overlap)}
          />
        </LabelledValue>

        <LabelledValue
          className="form-group col-md-4"
          label={<FormattedMessage id="INCISORS_HO_L" />}
        >
          <FormattedMessage
            id={translateRxOption("lower_horizontal_overlap", lower_horizontal_overlap)}
          />
        </LabelledValue>
      </div>
    </div>
  );
}

function OverjetInfo({
  prescription: {
    overjet,
    correct_retroclination_upper,
    correct_proclination_lower,
    correct_proclination_upper,
    correct_retroclination_lower,
  },
}: {
  prescription: TPrescription;
}) {
  return (
    <div className="row">
      {overjet == Overjet.CORRECT ? (
        <div className="form-group col-md-8">
          <LabelledValue label={<FormattedMessage id="OVERJET" />}>
            <FormattedMessage id={translateRxOption("overjet", overjet)} />
          </LabelledValue>

          <ul id="overjet-value-options" style={{ marginBottom: "20px" }}>
            {correct_retroclination_upper ? (
              <li>
                <FormattedMessage id="CORRECT_RETROCLINATION_UPPER" />
              </li>
            ) : null}

            {correct_proclination_lower ? (
              <li>
                <FormattedMessage id="CORRECT_PROCLINATION_LOWER" />
              </li>
            ) : null}

            {correct_proclination_upper ? (
              <li>
                <FormattedMessage id="CORRECT_PROCLINATION_UPPER" />
              </li>
            ) : null}

            {correct_retroclination_lower ? (
              <li>
                <FormattedMessage id="CORRECT_RETROCLINATION_LOWER" />
              </li>
            ) : null}
          </ul>
        </div>
      ) : (
        <LabelledValue className="form-group col-md-4" label={<FormattedMessage id="OVERJET" />}>
          <FormattedMessage id={translateRxOption("overjet", overjet)} />
        </LabelledValue>
      )}
    </div>
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";

import { CaninesRatioHowTo, CaninesRatioLeft, CaninesRatioRight } from "~/common/prescription";
import { Label } from "~/components/ui/label";

import { RecipeCheckbox } from "./recipe-shared";

export function RecipeCanines() {
  return (
    <div id="canines-section">
      <div className="tw-my-2.5 tw-flex tw-items-center tw-gap-1.5 tw-py-2.5 max-sm:tw-flex-col max-sm:tw-items-start">
        <h4 id="canines-heading" className="tw-m-0 tw-inline tw-font-bold">
          <FormattedMessage id="HEADER_CANINE" />
        </h4>

        <span className="tw-whitespace-nowrap tw-font-semibold tw-text-brand-gray">
          <FormattedMessage id="HEADER_CANINE_DESCRIPTION" />
        </span>
      </div>

      <div className="row">
        <div className="form-group col-md-3" style={{ marginBottom: 20 }}>
          <Label id="canines-ratio-right-label" readOnly>
            <FormattedMessage id="CANINE_RIGHT" />
          </Label>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="canine_ratio_right"
              value={CaninesRatioRight.CLASS_1}
              intlId="CR_R_I_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-right-label"]}
            />

            <RecipeCheckbox
              name="canine_ratio_right"
              value={CaninesRatioRight.CLASS_2}
              intlId="CR_R_II_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-right-label"]}
            />

            <RecipeCheckbox
              name="canine_ratio_right"
              value={CaninesRatioRight.CLASS_3}
              intlId="CR_R_III_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-right-label"]}
            />
          </div>
        </div>

        <div className="form-group col-md-3" style={{ marginBottom: 20 }}>
          <Label id="canines-ratio-left-label" readOnly>
            <FormattedMessage id="CANINE_LEFT" />
          </Label>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="canine_ratio_left"
              value={CaninesRatioLeft.CLASS_1}
              intlId="CR_L_I_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-left-label"]}
            />

            <RecipeCheckbox
              name="canine_ratio_left"
              value={CaninesRatioLeft.CLASS_2}
              intlId="CR_L_II_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-left-label"]}
            />

            <RecipeCheckbox
              name="canine_ratio_left"
              value={CaninesRatioLeft.CLASS_3}
              intlId="CR_L_III_CLASS"
              ariaLabelledBy={["canines-heading", "canines-ratio-left-label"]}
            />
          </div>
        </div>

        <div className="form-group col-md-3">
          <Label id="canines-ratio-howto-label" readOnly>
            <FormattedMessage id="CANINE_HOWTO" />
          </Label>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="canine_method"
              value={CaninesRatioHowTo.DISTALIZE}
              intlId="CRH_DISTALIZE"
              ariaLabelledBy={["canines-heading", "canines-ratio-howto-label"]}
            />

            <RecipeCheckbox
              name="canine_method"
              value={CaninesRatioHowTo.MESIALIZE}
              intlId="CRH_MESIALIZE"
              ariaLabelledBy={["canines-heading", "canines-ratio-howto-label"]}
            />
          </div>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="canine_ipr"
              value={undefined}
              intlId="CRH_IPR"
              ariaLabelledBy={["canines-heading", "canines-ratio-howto-label"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";

import type { DentalNotationType } from "~/common/constants";
import { createTeeth, ElasticTooth } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import { Label } from "~/components/ui/label";
import type { TPrescription } from "~/types/prescription";

export function PatientCardTeethItems({
  label,
  notation,
  defaultValue,
}: {
  label: MessageDescriptor["id"];
  notation: DentalNotationType;
  defaultValue: number[];
}) {
  const teeth = createTeeth();

  if (defaultValue.length == 0) {
    return null;
  }

  return (
    <div className="PatientTeethItems form-group">
      <Label id="teeth-item-list" readOnly>
        <FormattedMessage id={label} />
      </Label>

      <div className="row">
        <div className="col-xs-12">
          <div className="teeth-controls-upper">
            {teeth.slice(0, 2).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map((tooth) => (
                  <PatientCardToothItem
                    key={tooth}
                    tooth={tooth}
                    notation={notation}
                    defaultChecked={defaultValue.includes(tooth)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="col-xs-12">
          <div className="teeth-controls-lower">
            {teeth.slice(2, 4).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map((tooth) => (
                  <PatientCardToothItem
                    key={tooth}
                    tooth={tooth}
                    notation={notation}
                    defaultChecked={defaultValue.includes(tooth)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PatientCardToothItem({
  tooth,
  notation,
  defaultChecked,
}: {
  tooth: number;
  notation: DentalNotationType;
  defaultChecked: boolean;
}) {
  return (
    <label key={tooth}>
      {convertToDentalNotation(tooth, notation)}
      <br />

      <div className="checker">
        <label>
          <input id="checkbox" type="checkbox" disabled defaultChecked={defaultChecked} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label></label>
        </label>
      </div>
    </label>
  );
}

export function PatientCardElasticsTeethItems({
  name,
  label,
  notation,
  prescription,
}: {
  name: string;
  label: MessageDescriptor["id"];
  notation: DentalNotationType;
  prescription: TPrescription;
}) {
  const teeth = createTeeth();

  function setClassName(i: number) {
    switch (prescription.elastics_options[i]) {
      case ElasticTooth.HOOK:
        return "button_hook_tip_read_only hook";

      case ElasticTooth.BUTTON:
        return "button_hook_tip_read_only button";

      default:
        return "input_button";
    }
  }

  function setTooltip(i: number) {
    switch (prescription.elastics_options[i]) {
      case ElasticTooth.HOOK:
        return <FormattedMessage id="HOOK" tagName="span" />;

      case ElasticTooth.BUTTON:
        return <FormattedMessage id="BUTTON" tagName="span" />;

      default:
        return null;
    }
  }

  function renderInput(i: number) {
    const elastics_options = prescription.elastics_options;

    return (
      <label key={i}>
        {convertToDentalNotation(i, notation)}
        <br />

        <div className={setClassName(i)}>
          {elastics_options[i] == ElasticTooth.BUTTON || elastics_options[i] == ElasticTooth.HOOK
            ? setTooltip(i)
            : null}

          <input id="button_hook" name={name} type="checkbox" disabled={true} />
        </div>
      </label>
    );
  }

  return (
    <div className="PatientTeethItems form-group">
      <Label id="teeth-item-list" readOnly>
        <FormattedMessage id={label} />
      </Label>

      <div className="row">
        <div className="col-xs-12">
          <div className="teeth-controls-upper">
            {teeth.slice(0, 2).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map(renderInput)}
              </div>
            ))}
          </div>
        </div>

        <div className="col-xs-12">
          <div className="teeth-controls-lower">
            {teeth.slice(2, 4).map((quad) => (
              <div key={quad.join(",")} className="checkbox-list teeth-controls">
                {quad.map(renderInput)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

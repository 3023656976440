import React from "react";
import { FormattedMessage } from "react-intl";

import { PAYERS_TRANSLATION_MAP } from "~/common/constants";
import { SBCreditComponent } from "~/components/common/common";
import ProbablyFormattedMessage from "~/components/probably_formatted_message";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPatient } from "~/types/patient";

export function PatientCardPayment({ patient }: { patient: TPatient }) {
  return (
    <div>
      <h3 className="block" id="payment-section">
        <FormattedMessage id="BLOCKHEAD_PAYMENT" />
      </h3>

      <LabelledValue label={<FormattedMessage id="HEADER_PAYER" />}>
        <FormattedMessage
          id={patient.payer_id ? PAYERS_TRANSLATION_MAP[patient.payer_id] : "None"}
        />
      </LabelledValue>

      <LabelledValue label={<FormattedMessage id="HEADER_PAYMENT_METHOD" />}>
        <ProbablyFormattedMessage id={patient.course.payment_method} />
      </LabelledValue>

      <LabelledValue label={<FormattedMessage id="HEADER_PAYMENT_OPTION" />}>
        {patient.sber_credit ? (
          <SBCreditComponent showConditions={false} />
        ) : (
          <ProbablyFormattedMessage id={patient.course.payment_option} />
        )}
      </LabelledValue>

      <br />

      {patient.logistics_comment ? (
        <LabelledValue label={<FormattedMessage id="CLINIC_SHIP_COMMENTS" />}>
          {patient.logistics_comment}
        </LabelledValue>
      ) : null}
    </div>
  );
}

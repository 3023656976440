import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { CloseAllGaps, translateRxOption } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import { Label } from "~/components/ui/label";
import { LabelledValue } from "~/components/ui/labelled-value";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

export function PatientCardCloseAllGaps({
  user,
  prescription: { close_all_gaps, close_all_gaps_value, close_all_gaps_spaces },
}: {
  user: TUser;
  prescription: TPrescription;
}) {
  const intl = useIntl();
  const dentalNotation = user.preferences.dental_notation;

  return (
    <div>
      <Label id="close-all-gaps-label" readOnly>
        <FormattedMessage id="HEADER_CLOSE_ALL" />
      </Label>

      <div style={{ marginBottom: "20px" }} id="close-all-gaps-value">
        <p style={{ fontWeight: "600", marginBottom: "5px" }}>
          <FormattedMessage id={translateRxOption("close_all_gaps", close_all_gaps)} />
        </p>

        {close_all_gaps == CloseAllGaps.LEAVE_SPACE ? (
          <>
            {close_all_gaps_value ? (
              <p
                className="tw-mb-4 tw-ml-7 tw-whitespace-pre-line tw-italic"
                style={{ wordBreak: "break-word" }}
              >
                {close_all_gaps_value}
              </p>
            ) : null}

            {Object.keys(close_all_gaps_spaces).length > 0 ? (
              <LabelledValue
                label={<FormattedMessage id="CAG_LEAVE_SPACE_FOR" />}
                labelProps={{ semibold: true }}
              >
                {Object.entries(close_all_gaps_spaces).map(([key, value]) => {
                  const toothText = intl.formatMessage({ id: "TOOTH_NUMBER" });
                  const tooth = convertToDentalNotation(Number(key), dentalNotation);
                  const mmText = intl.formatMessage({ id: "MILLIMETRES" });
                  return <div key={key}>{`${toothText} ${tooth} - ${value} ${mmText}`}</div>;
                })}
              </LabelledValue>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
